import React from "react";
import { Link } from "react-router-dom";

function AboutUsBanner() {
  return (
    <section
      className="hero-banner py-5"
      style={{ backgroundColor: "#d9d9d9" }}
    >
      <div className="container">
        <div className="row row align-items-center">
          <div className="col-lg-5 offset-lg-1 order-lg-1">
            <img
              src="/assets/aboutus_banner.jpeg"
              className="img-fluid"
              alt="About Us"
              // add some styles to the image
              style={{
                borderRadius: "10px",
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
              }}
            />
          </div>
          <div className="col-lg-6">
            <p className="my-5" style={{ fontSize: "18px" }}>
              At The Femi Concept, we believe that clothing is more than just a
              covering; it's a statement of love for oneself and others. From
              chic ensembles to timeless classics, each piece is crafted with
              care, passion, and a commitment to spreading the love. Join us in
              embracing the Femi lifestyle – where fashion and love intertwine
              to create a unique and empowering experience for all.{" "}
            </p>
            <Link
              to={"/shop"}
              className="btn btn-outline-secondary btn-lg border black"
            >
              Shop
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUsBanner;
