import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import useFetch from "../hooks/useFetch";

export default function Carousel() {
  const url = "https://strapi.thefemiconcept.shop/api";
  const { loading, error, data } = useFetch(
    url + "/api/upload/files?filters[name][$eq]=HOME.jpeg"
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;
  return (
    <MDBCarousel className="custom-carousel">
      <MDBCarouselItem
        className="MDBCarouselItem"
        itemId={1}
        interval={6000}
        key={data.id}
      >
        <img
          src={url + data[0].url}
          className="d-block w-100"
          alt={data.name}
        />
      </MDBCarouselItem>
    </MDBCarousel>
  );
}
