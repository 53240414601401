import useFetch from '../hooks/useFetch';
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

export default function ProductsList() {

  const url = 'https://strapi.thefemiconcept.shop/api';
  const { loading, error, data } = useFetch(url + '/api/products?populate=*&&sort=rank:asc');

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  if (loading) return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <ClipLoader color={"#123abc"} loading={loading} css={override} size={150} />
    </div>
  );

  if (error) return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {/* error with a false red icon */}
      <div>
        <h2>Oops!, Something went wrong</h2>
        <p>Please Try Again Later</p>
      </div>
    </div>
  );

  const products = data.data;

  return (
    <section style={{backgroundColor: '#d9d9d9'}}>
    <div className="text-center container py-3">
      <h4 className="mt-3 mb-4"> <strong>All Products</strong> </h4>    
      <p className="my-5">
        Each piece is carefully crafted to inspire self-love and empower you to radiate positivity. <br></br>We envision a world where fashion is a force for good, and through Femi, we strive to contribute to a more inclusive and compassionate industry
      </p>
      <hr className="mb-5" />  
      <div className="row">
      
      {products.map(product => (
        <div key={product.id} className="col-lg-4 col-md-12 mb-4"> 
          <h5 className='mb-4'>{product.attributes.ProductName}</h5>
          <div className="card">
            <div className="bg-image hover-zoom ripple ripple-surface ripple-surface-light rounded" data-mdb-ripple-color="light">
              <img
                src={url+product.attributes.ProductPicture.data[0].attributes.formats.large.url}
                className="w-100"
                alt={product.attributes.ProductName}
              />
              <Link 
                to={"/product/" + product.id}
                className="stretched-link"
                style={{ textDecoration: "none" }}
              >
                <div className="mask">
                  <div className="d-flex justify-content-start align-items-end h-100">
                    <h5>
                      {/* <span className="badge bg-primary ms-2">New</span>
                      <span className="badge bg-success ms-2">Eco</span>
                      <span className="badge bg-danger ms-2">-10%</span> */}
                    </h5>
                  </div>
                </div>
                <div className="hover-overlay">
                  <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}/>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
    </section>
  )
}