import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import useFetch from "../hooks/useFetch";
import { handleSubmit } from "../Functions/HandleSubmit";

function CheckoutPage() {
  const { items, cartTotal, totalItems, emptyCart } = useCart();

  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [phonenum, setPhonenum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [Shipping, setShipping] = React.useState(0);
  const [formError, setFormError] = React.useState("");
  const [formSuccess, setFormSuccess] = React.useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false);
  const [submitBtnText, setSubmitBtnText] = React.useState("Submit Order");

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // fetch shipping cost
  const url = "https://strapi.thefemiconcept.shop/api";
  const { data } = useFetch(url + "/api/constants/1");
  // fetch shipping cost
  React.useEffect(() => {
    if (data) {
      setShipping(data.data.attributes.Value);
    }
  }, [data]);

  const resetForm = () => {
    setFname("");
    setLname("");
    setPhonenum("");
    setEmail("");
    setAddress("");
    setCity("");
  };

  return (
    <>
      <section
        className="h-100 h-custom"
        style={{ backgroundColor: "#d9d9d9" }}
      >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12">
              <div
                className="card card-registration card-registration-2"
                style={{ borderRadius: 15 }}
              >
                <div className="card-body p-0">
                  <div className="row g-0">
                    <div className="col-lg-8">
                      <div className="p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                          <h1 className="mb-0 text-black">Submit Order</h1>
                          <h6 className="mb-0 text-muted">
                            {items.length} Items
                          </h6>
                        </div>

                        <hr className="my-4" />

                        {items.map((item) => (
                          <div
                            key={item.id}
                            className="row mb-2 d-flex justify-content-between align-items-center"
                          >
                            <div className="col-3 col-md-1 col-lg-1 col-xl-1">
                              <img
                                src={item.image}
                                className="img-fluid rounded-3"
                                alt={item.name}
                              />
                            </div>
                            <div className="col-8 col-md-11 col-lg-11 col-xl-11">
                              <h6 className="text-black mb-0">
                                {item.name} {" - "} x{item.quantity}
                              </h6>
                            </div>
                          </div>
                        ))}

                        <hr className="my-4" />

                        {/* Submit Order Form */}
                        <form
                          className="row g-3"
                          onSubmit={(event) => {
                            if (isSubmitting) return;
                            setIsSubmitting(true);
                            handleSubmit(event, {
                              phonenum,
                              fname,
                              lname,
                              email,
                              address,
                              city,
                              cartTotal,
                              Shipping,
                              items,
                              setFormError,
                              setSubmitBtnDisabled,
                              setSubmitBtnText,
                              setFormSuccess,
                              emptyCart,
                              setIsSubmitting,
                              resetForm,
                            });
                          }}
                        >
                          <div className="col-md-6">
                            <label
                              htmlFor="inputFirstName"
                              className="form-label"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputFirstName"
                              value={fname}
                              onChange={(e) => setFname(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="inputLastName"
                              className="form-label"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputLastName"
                              value={lname}
                              onChange={(e) => setLname(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="inputEmail4" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail4"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="inputPhone" className="form-label">
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPhone"
                              value={phonenum}
                              onChange={(e) => setPhonenum(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="inputAddress"
                              className="form-label"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputAddress"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="inputState"
                              className="form-label
                                "
                            >
                              City
                            </label>
                            <select
                              id="inputState"
                              className="form-select"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              required
                            >
                              <option value="">Choose...</option>
                              <option value="Cairo">Cairo</option>
                              <option value="Alexandria">Alexandria</option>
                              <option value="Giza">Giza</option>
                              <option value="Port Said">Port Said</option>
                              <option value="Suez">Suez</option>
                              <option value="Luxor">Luxor</option>
                              <option value="al-Mansura">Al-Mansura</option>
                              <option value="Tanta">Tanta</option>
                              <option value="Asyut">Asyut</option>
                            </select>
                          </div>
                          <div className="col-12"></div>
                          {formError && (
                            <div className="alert alert-danger">
                              {formError}
                            </div>
                          )}
                          {formSuccess && (
                            <div className="alert alert-success">
                              {formSuccess}
                            </div>
                          )}

                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-dark btn-lg"
                              style={{ backgroundColor: "#3B540C" }}
                              disabled={submitBtnDisabled || isSubmitting}
                            >
                              {submitBtnText}
                            </button>
                          </div>
                        </form>
                        {/* Submit Order Form */}

                        <div className="pt-5">
                          <h6 className="mb-0">
                            <Link to={"/shop"} className="text-body">
                              <i className="fas fa-long-arrow-alt-left me-2" />
                              Back to Shop
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 bg-grey">
                      <div className="p-5">
                        <h3 className="fw-bold mb-5 mt-2 pt-1">Summary</h3>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-between mb-4">
                          <h5 className="text-uppercase">
                            items ({totalItems})
                          </h5>
                          <h5> {cartTotal} EGP</h5>
                        </div>

                        {/* SHIPPING */}

                        <div className="d-flex justify-content-between mb-4">
                          <h5 className="text-uppercase">SHIPPING</h5>
                          <h5> {Shipping} EGP</h5>
                        </div>

                        {/* SHIPPING */}

                        <div className="d-flex justify-content-between mb-4">
                          <h5 className="text-uppercase">VAT</h5>
                          <h5> 0.0 %</h5>
                        </div>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-between mb-5">
                          <h5 className="text-uppercase">Total price</h5>
                          <h5>{cartTotal + Shipping} EGP</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CheckoutPage;
