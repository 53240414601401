import React from "react";
import { CiCircleInfo, CiAt } from "react-icons/ci";

export default function AboutPage() {
  document.title = "FEMI | ABOUT";
  return (
    <>
      <section className="py-3 py-md-4 py-xl-5" style={{backgroundColor: '#d9d9d9'}}>
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6 col-xl-5">
              <img
                className="img-fluid rounded"
                loading="lazy"
                src="/assets/aboutus_page.JPG"
                alt="About us"
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h1 className="h2 mb-2">About Us</h1>
                  <p className="mb-8">
                  Welcome to FEMI, where fashion is an expression of love. Born out of passion and a deep commitment to spreading love through style, Femi is not just a brand; it's a movement. In our world, Femi means love, and every piece we create is infused with that sentiment. Our startup journey is a celebration of diversity, inclusivity, and the belief that clothing can be a powerful form of self-love and expression. At Femi, we strive to bring you unique, trendsetting designs that resonate with the love you carry within. Join us on this journey of embracing and sharing love through fashion. Each collection is a testament to our dedication to creating a more loving and stylish world. Spread the love with Femi – because every outfit tells a story, and yours is meant to be a love story. Let Femi be more than just a brand; let it be a celebration of your individuality and a reminder that love is always in vogue.                     </p>
                  <div className="row gy-4 gy-md-0 gx-xxl-5X">
                    <div className="col-12 col-md-6">
                      <div className="d-flex">
                        <div className="me-4 text-primary">
                          <CiCircleInfo size={"35px"} color="black" />
                        </div>
                        <div>
                          <h4 className="mb-3">Order</h4>
                          <p className="text-secondary mb-0">
                            Order through our website or Instagram page
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex">
                        <div className="me-4 text-primary">
                          <CiAt size={"35px"} color="black" />
                        </div>
                        <div>
                          <h4 className="mb-3">Contact Us</h4>
                          <p className="text-secondary mb-0">
                          at <a href="mailto:help@thefemiconcept.shop">help@thefemiconcept.shop</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
