import React from 'react' 
import { Link } from 'react-router-dom';
import { CiShoppingCart, CiMenuBurger } from "react-icons/ci";

export default function SiteHeader(props) {
  return (
    <>
    <nav style={{ 
      backgroundColor: 'black', 
      height: '60px', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      position: 'sticky', 
      top: 0, 
      zIndex: 1000 
    }}>
      <CiMenuBurger style={{ color: 'white', width: '25px', height: '25px', marginLeft: '20px' }} onClick={() => {props.openSideNav()}} />
      <Link to={"/"}> <img src="/assets/logo.png" style={{ width: '150px', height: '140px', margin: '0 auto' }} alt="/" /> </Link>
      <Link to={"/cart"}> <CiShoppingCart style={{ color: 'white', width: '25px', height: '25px', marginRight: '20px' }} /> </Link>
    </nav>
    </>
  );
}