import React from "react";
import { CiInstagram } from "react-icons/ci";
import { RiTiktokLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Qodera from "./Qodera";

export default function SiteFooter() {
  return (
    <>
      <section className="">
        <footer className="bg-body-tertiary text-center text-md-start">
          <div className="container pt-4">
            <section className="mb-4">
              <a
                className="btn btn-link btn-floating btn-lg text-body m-1"
                href="https://www.tiktok.com/@thefemiconcept"
              >
                <RiTiktokLine
                  size={"30px"}
                  color="black"
                  style={{ height: "45px" }}
                />{" "}
              </a>
              <a
                className="btn btn-link btn-floating btn-lg text-body m-1"
                href="https://www.instagram.com/thefemiconcept?igsh=M3RsZHZtaHZocnJ6"
              >
                <CiInstagram
                  size={"30px"}
                  color="black"
                  style={{ height: "45px" }}
                />{" "}
              </a>
            </section>
          </div>

          <div className="container p-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase">FEMI</h5>
                <p>
                  Each collection is a testament to our dedication to creating a
                  more loving and stylish world. Spread the love with Femi –
                  because every outfit tells a story, and yours is meant to be a
                  love story.
                </p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0"></div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Links</h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    {" "}
                    <Link className="text-body" to={"/"}>
                      {" "}
                      Home{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link className="text-body" to={"/shop"}>
                      {" "}
                      Shop{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link className="text-body" to={"/about"}>
                      {" "}
                      About{" "}
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Qodera />
        </footer>
      </section>
    </>
  );
}
