import axios from "axios";
import SendEmailToAdmin from "./SendEmailToAdmin.js";
import SendEmailToCustomer from "./SendEmailToCustomer.js";
import { decrementQuantity } from "./DecrementQuantity";

const API_URL = "https://strapi.thefemiconcept.shop/api/api/orders";

const transformItemsToOrder = (items) =>
  items.map((item) => ({
    __component: "order.order",
    ProductName: item.name,
    ProductQuantity: item.quantity,
    ProductSize: item.size,
  }));

export const handleSubmit = async (
  event,
  {
    phonenum,
    fname,
    lname,
    email,
    address,
    city,
    cartTotal,
    Shipping,
    items,
    setFormError,
    setSubmitBtnDisabled,
    setSubmitBtnText,
    setFormSuccess,
    emptyCart,
    isSubmitting,
    setIsSubmitting,
    resetForm,
  }
) => {
  event.preventDefault();

  if (isSubmitting) return;
  setIsSubmitting(true); // Set isSubmitting to true when the form is being submitted
  setSubmitBtnDisabled(true);
  setSubmitBtnText("Submitting...");

  if (phonenum.length !== 11) {
    setFormError("Phone number must be 11 digits");
    setSubmitBtnDisabled(false);
    setIsSubmitting(false);
    setSubmitBtnText("Submit Order");
    return;
  }

  try {
    const response = await axios.post(
      API_URL,
      {
        data: {
          fname,
          lname,
          email,
          phonenum,
          address,
          city,
          total: cartTotal + Shipping,
          orderx: transformItemsToOrder(items),
          date: new Date().toISOString(),
        },
      },
      { timeout: 5000 }
    );

    if (response.status === 200) {
      await Promise.all([
        decrementQuantity(items),
        SendEmailToCustomer(
          email,
          items,
          cartTotal + Shipping,
          fname,
          address,
          Shipping
        ),
        SendEmailToAdmin(
          items,
          cartTotal + Shipping,
          fname,
          lname,
          email,
          phonenum,
          address,
          Shipping
        ),
      ]);
      setFormSuccess("Order Submitted Successfully");
      resetForm();
      setTimeout(() => {
        window.location.href = "/";
        emptyCart();
      }, 3000);
    }
  } catch (error) {
    setFormError(
      "An error occurred while submitting the order, Please try again."
    );
    setIsSubmitting(false);
    setSubmitBtnDisabled(false);
    setSubmitBtnText("Submit Order");
  }
};
