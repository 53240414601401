import React from 'react'

export default function Qodera() {
    return (
        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
            <span className="flex items-center justify-center text-neutral-600">
                <a
                    className='text-body'
                    href="https://www.linkedin.com/company/qodera"
                >
                    Qodera Software Solutions&nbsp;
                </a>  © 2023
            </span>
        </div>
    )
}