import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function SideNav(props) {
  const sideNavRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sideNavRef.current &&
        !sideNavRef.current.contains(event.target) &&
        props.width !== "0px"
      ) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.width, props.onClose]);
  return (
    <>
      <div ref={sideNavRef} className="sidenav" style={{ width: props.width }}>
        <IoCloseOutline
          style={{
            color: "black",
            width: "25px",
            height: "25px",
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
          onClick={() => {
            props.onClose();
          }}
        />

        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "30px"}}>
            <br></br><br></br><br></br><br></br>
            <Link className="sidenav-items" to={"/"} onClick={props.onClose}>Home</Link>
            <hr className="divider" />
            <br></br>
            <Link className="sidenav-items" to={"/shop"} onClick={props.onClose}>Shop</Link>
            <hr className="divider" />
            {/* <br></br>
            <Link className="sidenav-items" to={"/login"} onClick={props.onClose}>Login</Link>
            <hr className="divider" /> */}
            <br></br>
            <Link className="sidenav-items" to={"/about"} onClick={props.onClose}>About</Link>
            <hr className="divider" />
        </div>
        
      </div>
    </>
  );
}

export default SideNav;
