import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import useFetch from "../hooks/useFetch";

function Cart() {
  const {
    items,
    removeItem,
    cartTotal,
    totalItems,
    updateItemQuantity,
    isEmpty,
  } = useCart();

  const [Shipping, setShipping] = React.useState(0);

  // fetch api
  const url = "https://strapi.thefemiconcept.shop/api";
  const { data } = useFetch(url + "/api/constants/1");
  // fetch api
  useEffect(() => {
    if (data) {
      setShipping(data.data.attributes.Value);
    }
  }, [data]);

  return (
    <section className="h-100 h-custom" style={{ backgroundColor: "#d9d9d9" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12">
            <div
              className="card card-registration card-registration-2"
              style={{ borderRadius: 15 }}
            >
              <div className="card-body p-0">
                <div className="row g-0">
                  <div className="col-lg-8">
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center mb-5">
                        <h1 className="mb-0 text-black">Shopping Cart</h1>
                        <h6 className="mb-0 text-muted">
                          {items.length} Items
                        </h6>
                      </div>

                      <hr className="my-4" />

                      {items.map((item) => (
                        <div
                          key={item.id}
                          className="row mb-4 d-flex justify-content-between align-items-center"
                        >
                          <div className="col-6 col-md-2 col-lg-2 col-xl-2">
                            <img
                              src={item.image}
                              className="img-fluid rounded-3"
                              alt={item.name}
                            />
                          </div>

                          <div className="col-6 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <p className="text-muted mb-0">{item.size}</p>
                            <h5 className="text-black mb-0">{item.name}</h5>
                          </div>

                          <div className="col-4 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center mt-3">
                            <button
                              className="btn btn-link px-2 text-black"
                              onClick={() =>
                                updateItemQuantity(item.id, item.quantity - 1)
                              }
                            >
                              {" "}
                              <i className="fas fa-minus" />{" "}
                            </button>
                            <input
                              min={0}
                              value={item.quantity}
                              onChange={(e) =>
                                updateItemQuantity(
                                  item.id,
                                  parseInt(e.target.value)
                                )
                              }
                              className="mx-auto text-center border-0"
                              style={{ width: 30 }}
                              readOnly={true}
                            />
                            <button
                              className="btn btn-link px-2 text-black"
                              onClick={() =>
                                updateItemQuantity(item.id, item.quantity + 1)
                              }
                            >
                              {" "}
                              <i className="fas fa-plus" />{" "}
                            </button>
                          </div>
                          <div className="col-4 col-md-3 col-lg-2 col-xl-2 mt-3">
                            <h6 className="mb-0">{item.price} EGP</h6>
                          </div>
                          <div className="col-4 col-md-1 col-lg-1 col-xl-1 text-end mt-3">
                            <button
                              type="button"
                              className="btn btn-floating bg-dark"
                              onClick={() => removeItem(item.id)}
                            >
                              {" "}
                              <i className="fas fa-times text-light" />{" "}
                            </button>
                          </div>
                          <hr className="my-4" />
                        </div>
                      ))}

                      <div className="pt-5">
                        <h6 className="mb-0">
                          <Link to={"/shop"} className="text-body">
                            <i className="fas fa-long-arrow-alt-left me-2" />
                            Back to Shop
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 bg-grey">
                    <div className="p-5">
                      <h3 className="fw-bold mb-5 mt-2 pt-1 ">Summary</h3>
                      <hr className="my-4" />
                      <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-uppercase">items ({totalItems})</h5>
                        <h5> {cartTotal} EGP</h5>
                      </div>

                      {/* SHIPPING */}

                      <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-uppercase">SHIPPING</h5>
                        <h5> {Shipping} EGP</h5>
                      </div>

                      {/* SHIPPING */}

                      <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-uppercase">VAT</h5>
                        <h5> 0.0 %</h5>
                      </div>

                      <hr className="my-4" />
                      <div className="d-flex justify-content-between mb-5">
                        <h5 className="text-uppercase">Total price</h5>
                        <h5>{cartTotal + Shipping} EGP</h5>
                      </div>

                      {!isEmpty && (
                        <Link
                          to={"/checkout"}
                          type="button"
                          className="btn btn-dark btn-block btn-lg"
                          data-mdb-ripple-color="dark"
                          style={{ backgroundColor: "#3B540C" }}
                        >
                          {" "}
                          Checkout{" "}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cart;
