import { Link } from "react-router-dom";
import { useState } from "react";

export default function FeaturedProducts(props) {
  const [image, setImage] = useState(props.picture);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setImage(props.hoverPicture);
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setImage(props.picture);
    setIsHovered(false);
  };
  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mx-auto" >
       <div className="bg-image hover-zoom ripple shadow-1-strong rounded">
        <Link to={"/shop"}>
        <img 
        src={image} 
        alt={props.productname}
        className="w-100 featured-image"
        onMouseEnter={handleMouseEnter}
        onTouchStart={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchEnd={handleMouseLeave}
        />
        </Link>
        <a>
          <div 
          onMouseEnter={handleMouseEnter}
          onTouchStart={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleMouseLeave}
          className={`product-name ${isHovered ? 'visible' : ''}`}>
            {
              props.productname
            }
          </div>
        </a>
        </div>
        
      </div>
     
    </>
  );
}
