import "./App.css";
import SiteHeader from "./components/SiteHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import SideNav from "./components/SideNav";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProductsPage from "./pages/ProductsPage";
import SiteFooter from "./components/SiteFooter";
import Cart from "./pages/Cart";
import ProductDetails from "./components/ProductDetails";
import { CartProvider } from "react-use-cart";
import CheckoutPage from "./pages/CheckoutPage";

function App() {
  const [sideNavWidth, setSideNavWidth] = useState("0px");

  return (
    <>
      <CartProvider>
        <div
          className={`overlay ${
            sideNavWidth !== "0px" ? "overlay-active" : ""
          }`}
        />
        <SiteHeader
          openSideNav={() => {
            window.innerWidth <= 768
              ? setSideNavWidth("60%")
              : setSideNavWidth("30%");
          }}
        />
        <SideNav
          width={sideNavWidth}
          onClose={() => {
            setSideNavWidth("0px");
          }}
        />

        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/shop" element={<ProductsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<CheckoutPage />} />
        </Routes>

        <SiteFooter />
      </CartProvider>
    </>
  );
}

export default App;
