import React from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import useFetch from "../hooks/useFetch";
import { useRef } from "react";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";

export default function ProductDetails() {
  const navigate = useNavigate();

  const mainImageRef = useRef(null);

  const { addItem } = useCart();

  const { id } = useParams();

  const [size, setSize] = React.useState("Small");
  const [qty, setQty] = React.useState(1);

  const url = "https://strapi.thefemiconcept.shop/api";
  const { loading, error, data } = useFetch(
    url + "/api/products/" + id + "?populate=*"
  );

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader
          color={"#123abc"}
          loading={loading}
          css={override}
          size={150}
        />
      </div>
    );

  if (error)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* error with a false red icon */}
        <div>
          <h2>Oops!, Something went wrong</h2>
          <p>Please Try Again Later</p>
        </div>
      </div>
    );

  // Thumbnail Click
  const changeImage = (picture) => {
    return () => {
      mainImageRef.current.src = url + picture.attributes.formats.large.url;
    };
  };

  const product = data.data;
  const cartprod = {
    id: product.id,
    name: product.attributes.ProductName,
    price: product.attributes.ProductPrice,
    size: size,
    image:
      url +
      product.attributes.ProductPicture.data[0].attributes.formats.large.url,
  };

  return (
    <section className="py-5" style={{ backgroundColor: "#d9d9d9" }}>
      <div className="container">
        <div className="row gx-md-4 gx-lg-5 gx-xl-8">
          <aside className="col-lg-6">
            {/* Main Image */}
            <div
              className="rounded-4 mb-3 d-flex justify-content-center"
              style={{ display: "inline-block" }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "100vh", margin: "0px" }}
                className="rounded-4 fit"
                src={
                  url +
                  product.attributes.ProductPicture.data[0].attributes.formats
                    .large.url
                }
                ref={mainImageRef}
              />
            </div>
            {/* Main Image */}

            {/* Thumbnails */}
            {product.attributes.ProductPicture.data.length > 0 && (
              <div className="d-flex justify-content-center mb-3">
                {product.attributes.ProductPicture.data.map((picture) => (
                  <a key={picture.id} onClick={changeImage(picture)}>
                    <div style={{ marginRight: "10px" }}>
                      <img
                        width={45}
                        height={65}
                        className="rounded-2"
                        src={url + picture.attributes.formats.large.url}
                      />
                    </div>
                  </a>
                ))}
              </div>
            )}
            {/* Thumbnails */}
          </aside>

          <main className="col-lg-6">
            <div className="ps-lg-3">
              {/* Title */}
              <h4 className="title text-dark">
                {product.attributes.ProductName}
              </h4>
              {/* Title */}

              {/* Availlability */}
              <div className="d-flex flex-row my-3">
                {product.attributes.ProductQuantity > 0 ? (
                  <span className="text-success">
                    <i className="fas fa-check-circle fa-sm mx-1" />
                    In stock
                  </span>
                ) : (
                  <span className="text-danger">
                    <i className="fas fa-times-circle fa-sm mx-1" />
                    Out of stock
                  </span>
                )}
              </div>
              {/* Availlability */}

              {/* Price */}
              <div className="mb-3">
                <span className="h5">
                  {product.attributes.ProductPrice}.00 EGP
                </span>
                {product.attributes.ProductOldPrice && (
                  <span className="h6 ms-4 text-decoration-line-through text-danger">
                    {product.attributes.ProductOldPrice}.00 EGP
                  </span>
                )}
              </div>
              {/* Price */}

              {/* Description */}
              <p>{product.attributes.ProductDescription}</p>
              {/* Description */}

              {/* Details */}
              <div className="row">
                <dt className="col-3">Type:</dt>
                <dd className="col-9">Regular</dd>
                <dt className="col-3">Color</dt>
                <dd className="col-9">{product.attributes.ProductColor}</dd>
                <dt className="col-3">Material</dt>
                <dd className="col-9">{product.attributes.ProductMaterial}</dd>
              </div>
              {/* Details */}

              <hr />

              <div className="row mb-4">
                {/* Size */}
                <div className="col-md-4 col-6">
                  <label className="mb-2">Size</label>
                  <select
                    className="form-select border border-secondary"
                    style={{ height: 35 }}
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  >
                    {product.attributes.Small ? <option>Small</option> : null}
                    {product.attributes.Medium ? <option>Medium</option> : null}
                    {product.attributes.Large ? <option>Large</option> : null}
                  </select>
                </div>
                {/* Size */}

                <div className="col-md-4 col-6 mb-3">
                  {/* Quantity */}
                  <label className="mb-2 d-block">Quantity</label>
                  <div className="input-group mb-3" style={{ width: 170 }}>
                    <button
                      className="btn btn-white border border-secondary px-3"
                      type="button"
                      data-mdb-ripple-color="dark"
                      onClick={() => setQty(qty > 1 ? qty - 1 : 0)}
                    >
                      <i className="fas fa-minus" />
                    </button>
                    <input
                      type="text"
                      className="form-control text-center border border-secondary"
                      value={qty}
                      aria-describedby="button-addon1"
                      onChange={(e) => setQty(e.target.value)}
                      readOnly={true}
                    />
                    <button
                      className="btn btn-white border border-secondary px-3"
                      type="button"
                      id="button-addon2"
                      data-mdb-ripple-color="dark"
                      // onClick={() => setQty(qty + 1)}
                      onClick={() =>
                        setQty(
                          qty < product.attributes.ProductQuantity
                            ? qty + 1
                            : product.attributes.ProductQuantity
                        )
                      }
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                  {/* Quantity */}
                </div>
              </div>
              {product.attributes.ProductQuantity > 0 ? (
                <a
                  id="buy-now"
                  className="btn btn-warning btn-lg border shadow-0 mx-1 bg-dark"
                  onClick={() => {
                    qty > 0
                      ? (() => {
                          addItem(cartprod, qty);
                          navigate("/checkout");
                        })()
                      : (() => {
                          alert("Please select a quantity");
                        })();
                  }}
                >
                  {" "}
                  Buy Now{" "}
                </a>
              ) : (
                <a
                  id="buy-now"
                  className="btn btn-warning btn-lg border shadow-0 mx-1 bg-dark"
                  disabled
                >
                  {" "}
                  Buy Now{" "}
                </a>
              )}
              {product.attributes.ProductQuantity > 0 ? (
                <a
                  id="addto-cart"
                  className="btn btn-light btn-lg border border-secondary"
                  onClick={() => {
                    qty > 0
                      ? (() => {
                          addItem(cartprod, qty);
                          alert("Added to cart");
                          navigate("/cart");
                        })()
                      : (() => {
                          alert("Please select a quantity");
                        })();
                  }}
                >
                  <i className="me-1 fa fa-shopping-basket" /> Add to cart
                </a>
              ) : (
                <a
                  id="addto-cart"
                  className="btn btn-light btn-lg border border-secondary"
                  disabled
                >
                  <i className="me-1 fa fa-shopping-basket" /> Add to cart
                </a>
              )}
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
