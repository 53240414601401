import React from "react";

function HeroBanner() {
  return (
    <>
      <section
        className="hero-banner py-5"
        style={{ backgroundColor: "#d9d9d9" }}
      >
        <div className="container">
          <div className="row justify-content-md-center align-items-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7">
              <h3
                className="text-center mt-3"
                style={{ fontSize: "46px", color: "#3B540C" }}
              >
                Welcome to the Femi Club
              </h3>
              <p
                className="lead text-black text-center mb-5 d-flex justify-content-sm-center my-5"
                style={{ fontSize: "18px" }}
              >
                Our mission at Femi is to redefine the fashion landscape by
                curating collections that not only reflect the latest trends but
                also resonate with the values of compassion, diversity, and
                sustainability. Each piece is carefully crafted to inspire
                self-love and empower you to radiate positivity. We envision a
                world where fashion is a force for good, and through Femi, we
                strive to contribute to a more inclusive and compassionate
                industry.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroBanner;
