import axios from "axios";

const API_URL = "https://strapi.thefemiconcept.shop/api/api/products/";

export const decrementQuantity = async (items) => {
  const updateQuantityPromises = items.map(async (item) => {
    try {
      const decrementQuantityResponse = await axios.get(API_URL + item.id);
      let pQty = decrementQuantityResponse.data.data.attributes.ProductQuantity;
      pQty = parseInt(pQty) - parseInt(item.quantity);

      const response = await axios.put(API_URL + item.id, {
        data: {
          ProductQuantity: pQty,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to update quantity");
      }
    } catch (error) {
      console.error(`Failed to update quantity for item ${item.id}: ${error}`);
    }
  });

  await Promise.all(updateQuantityPromises);
};
