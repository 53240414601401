import React from 'react'
import Carousel from '../components/Carousel';
import FeaturedProducts from '../components/FeaturedProducts';
import useFetch from '../hooks/useFetch';
import AboutUsBanner from '../components/AboutUsBanner';
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import HeroBanner from '../components/HeroBanner';

export default function HomePage() {
  const url = 'https://strapi.thefemiconcept.shop/api';
  const { loading, error, data } = useFetch(url + '/api/products?populate=*');

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  if (loading) return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <ClipLoader color={"#123abc"} loading={loading} css={override} size={150} />
    </div>
  );

  if (error) return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {/* error with a false red icon */}
      <div>
        <h2>Oops!, Something went wrong</h2>
        <p>Please Try Again Later</p>
      </div>
    </div>
  );

  const products = data.data;

  const featuredProducts = products.filter(product => product.attributes.Featured);


  return (
    <>
      <title>Femi | Home</title>
      <Carousel />

      {/* Featured Products */}
      <section style={{backgroundColor: '#d9d9d9'}}>
        <div className="container py-5">
          <h4 className="text-center mb-2"> <strong>Featured</strong> </h4>
          <hr className='mb-4'></hr>
            <div className="row">

                {featuredProducts.map (product => (
                  <FeaturedProducts key={product.id} productname={product.attributes.ProductName} numberOfProducts={featuredProducts.length} picture={url+product.attributes.ProductPicture.data[1].attributes.formats.large.url} hoverPicture={url+product.attributes.ProductPicture.data[1].attributes.formats.large.url} price={product.attributes.ProductPrice} />
                ))}

            </div>
            <hr></hr>
        </div>
      </section>

    

      {/* Featured Products */}
      <HeroBanner />
      <AboutUsBanner />


    </>
  )
}
