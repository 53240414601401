import React from 'react'
import ProductsList from '../components/ProductsList'

export default function ProductsPage() {
  document.title = "FEMI | SHOP";
  return (
    <>
     <ProductsList />
    </>
  )
}
